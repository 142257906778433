import { Container, Grid, Typography, Theme, Box, useTheme } from '@mui/material';
import React from 'react';
import { useAllMembers } from 'src/hooks/useAllMembers';
import Emoji from 'components/Utils/Emoji';
import FadeUp from 'components/Animated/FadeUp';
import MemberCard from 'components/Cards/MemberCard';
import RecruitMentorCircle from './RecruitMentorCircle';
import sandboxData from 'data/sandbox.json';

const { needRecruitMentors, mentorsThisRound } = sandboxData;

const MentorSection = () => {
  const { spacing } = useTheme<Theme>();
  const members = useAllMembers();
  const mentors = members.filter(member => mentorsThisRound.includes(member.id));

  return (
    <Box
      component="section"
      sx={{
        padding: {
          md: `${spacing(15)} 0`,
          xs: `${spacing(10)} 0`,
        },
      }}
    >
      <Container>
        <Typography
          variant="h4"
          component="h2"
          sx={{
            fontWeight: 'bold',
            textAlign: 'center',
            marginBottom: {
              md: 2,
              xs: 1,
            },
            fontSize: {
              md: 'h4.fontSize',
              xs: 20,
            },
          }}
        >
          12주 간 샌드박스를 함께할 메이트들이에요
        </Typography>
        <Typography
          sx={{
            marginBottom: {
              md: 10,
              xs: 4,
            },
            textAlign: 'center',
            fontSize: {
              md: 18,
              xs: 16,
            },
          }}
        >
          메이트들은 각자 1개의 팀을 맡아서 12주 동안 여러분을 도와줄 거예요. <Emoji emoji="🙌" name="hi" />
          <br />
          샌드박스에 참여하는 모든 분들은 다른 팀의 메이트나 팀원들과도 자유롭게 소통할 수 있고, <br />
          메이트들은 같은 직군끼리 모인 조직인 챕터에서도 만날 수 있으니, 우리 팀 메이트의 포지션이 내 포지션과
          다르더라도 걱정 마세요.
        </Typography>
        <Grid container>
          {mentors.map(mentor => (
            <Grid
              item
              key={mentor.id}
              xs={6}
              sm={4}
              md="auto"
              sx={{
                width: {
                  md: '25%',
                  xs: '50%',
                },
              }}
            >
              <FadeUp>
                <MemberCard member={mentor} backgroundColor="#FCD1CA" />
              </FadeUp>
            </Grid>
          ))}
          {needRecruitMentors ? (
            <Grid
              item
              xs={6}
              sm={4}
              md="auto"
              sx={{
                width: {
                  md: '25%',
                  xs: '50%',
                },
              }}
            >
              <FadeUp>
                <RecruitMentorCircle />
              </FadeUp>
            </Grid>
          ) : null}
        </Grid>
      </Container>
    </Box>
  );
};

export default MentorSection;
