import React from 'react';
import {
  Card,
  CardContent,
  Container,
  Typography,
  Avatar,
  CardHeader,
  CardActionArea,
  Theme,
  Box,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import Masonry from '@mui/lab/Masonry';
import MasonryItem from '@mui/lab/MasonryItem';
import { useMentoringReviews } from 'src/hooks/useMentoringReview';

const ReviewSection = () => {
  const { spacing } = useTheme<Theme>();
  const reviews = useMentoringReviews();
  const matches = useMediaQuery('(max-width: 1200px)');
  console.log(matches);

  return (
    <Box component="section" sx={{ padding: `${spacing(15)} 0` }}>
      <Container>
        <Typography
          variant="h4"
          component="h2"
          sx={{
            fontWeight: 'bold',
            textAlign: 'center',
            marginBottom: {
              md: 2,
              xs: 1,
            },
            fontSize: {
              md: 'h2.fontSize',
              xs: 20,
            },
          }}
        >
          샌드박스 후기
        </Typography>
        <Typography
          sx={{
            marginBottom: {
              md: 10,
              xs: 4,
            },
            textAlign: 'center',
            fontSize: {
              md: 18,
              xs: 16,
            },
          }}
        >
          루비콘 샌드박스에 참여했던 팀원들이 느꼈던 생생한 경험이 궁금하다면
          <br />
          팀원들이 직접 작성한 후기 포스팅을 한번 읽어보세요.
        </Typography>
        <Masonry columns={matches ? 1 : 4} spacing={2} sx={{ padding: matches ? 0 : 5, overflow: 'visible' }}>
          {reviews.map(({ title, author, url, description }, index) => (
            <MasonryItem key={index}>
              <Card onClick={() => window.open(url)}>
                <CardActionArea>
                  <CardHeader
                    avatar={<Avatar alt={author.name} src={author.profileImg} />}
                    title={author.name}
                    subheader={author.role}
                  />
                  <CardContent>
                    <Typography variant="body1" gutterBottom={true} sx={{ fontWeight: 'bold' }}>
                      {title}
                    </Typography>
                    <Typography variant="body2">{description}</Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </MasonryItem>
          ))}
        </Masonry>
      </Container>
    </Box>
  );
};

export default ReviewSection;
