import { Grid, Typography } from '@mui/material';
import React from 'react';
import FadeUp from 'components/Animated/FadeUp';

const weeks = [
  {
    title: '아이템 선정 / MVP 정의',
    description:
      '팀원들과 아이디어를 나누고 팀원들이 함께 바라볼 가슴 뛰는 목표를 세우며 그 아이디어를 실현할 MVP를 정의합니다.',
    startWeek: 1,
    endWeek: 3,
  },
  {
    title: '디자인 및 개발 진행 / 첫 배포',
    description: 'MVP를 1~2주 안에 빠르게 디자인하고 개발하여 첫 배포하는 것을 목표로 합니다.',
    startWeek: 4,
    endWeek: 6,
  },
  {
    title: '배포 후 데이터 분석 / 이터레이션',
    description: '배포한 MVP를 사용하는 사용자들의 데이터를 토대로 합리적인 가설을 세워 제품을 개선합니다.',
    startWeek: 7,
    endWeek: 9,
  },
  {
    title: '지표 공유 / 최종 회고',
    description:
      '제품 개선 이터레이션을 계속 해서 반복하며 성장시킨 지표를 샌드박스에 참여한 사람들에게 공유하고 최종 회고를 진행합니다.',
    startWeek: 10,
    endWeek: 12,
  },
];

const CurriculumSchedules = () => {
  return (
    <Grid container spacing={2}>
      {weeks.map(({ title, description, startWeek, endWeek }) => (
        <Grid item xs={12} md={3} key={title}>
          <FadeUp>
            <Typography sx={{ fontSize: 13, fontWeight: 'bold', color: '#505050', marginBottom: '10px' }}>
              week {startWeek} - {endWeek}
            </Typography>
            <Typography sx={{ fontWeight: 'bold', color: '#505050', fontSize: 16 }}>{title}</Typography>
            <Typography sx={{ color: '#505050', fontSize: 14, marginBottom: 2 }}>{description}</Typography>
          </FadeUp>
        </Grid>
      ))}
    </Grid>
  );
};

export default CurriculumSchedules;
