import React from 'react';

type PointCoords = [number, number];

interface Props {
  className?: string;
  fill: string;
  size: { width: number; height: number };
  points: [PointCoords, PointCoords, PointCoords];
}

/**
 * @description points: [x, y] 순서로 된 튜플임. x, y는 비율을 의미한다. ex) [0.5, 0.7]
 */
const TriangleSvg = ({ size: { width, height }, points, fill, className }: Props) => {
  const path = points.map(([x, y]) => `${width * x}, ${height * y}`).join(' ');

  return (
    <svg width={width} height={height} className={className}>
      <polygon points={path} fill={fill} />
    </svg>
  );
};

export default TriangleSvg;
