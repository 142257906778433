import { Box, Container, Typography, useTheme, Theme } from '@mui/material';
import React from 'react';
import CurriculumSchedules from './CurriculumSchedules';
import MentoringSessions from './MentoringSessions';

const CurriculumSection = () => {
  const { spacing } = useTheme<Theme>();
  return (
    <section>
      <Container>
        <Typography
          variant="h4"
          component="h2"
          sx={{ fontWeight: 'bold', textAlign: 'center', marginBottom: 2, fontSize: { xs: 24, md: 'h4.fontSize' } }}
        >
          샌드박스 타임라인 미리보기
        </Typography>
        <Typography
          sx={{
            marginBottom: {
              md: 5,
              xs: 4,
            },
            textAlign: 'center',
            fontSize: {
              md: 18,
              xs: 16,
            },
          }}
        >
          여러분은 루비콘 샌드박스에서 12주 동안 빠르게 MVP를 릴리즈하고 데이터를 수집하여 제품을 개선하는 과정을 반복할
          거에요.
          <br />
          2주마다 열리는 올핸즈 미팅에서는 루비콘 팀이 준비한 세션 발표도 있으니 놓치지 마세요!
        </Typography>
      </Container>
      <Box
        sx={{
          padding: {
            md: '88px 0 115px',
            xs: `${spacing(5)} 0`,
          },
          backgroundColor: '#F4F6F9',
        }}
        display="flex"
        flexDirection="column"
      >
        <Container>
          <CurriculumSchedules />
          <MentoringSessions />
        </Container>
      </Box>
    </section>
  );
};

export default CurriculumSection;
