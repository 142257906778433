import {
  Container,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Theme,
  useTheme,
  Box,
} from '@mui/material';
import React, { useState, ComponentProps } from 'react';
import BenefitCard from 'components/Cards/BenefitCard';
import FadeUp from 'components/Animated/FadeUp';
import { useBooleanState } from '@lubycon/react';
import { mentoringIntroPageLogger } from 'src/utils/loggers';

interface MentoringBenefit {
  title: string;
  summary: string;
  image: string;
  description: string;
}
const benefits: MentoringBenefit[] = [
  {
    title: '뇌피셜은 이제 그만!\n데이터를 보고 만들어요',
    summary:
      '업계를 리드하는 회사들은 절대 뇌피셜로 제품을 개발하지 않고 철저한 데이터 기반 의사결정을 통해 제품을 만들고 개선해나가요.',
    image: 'https://assets.lubycon.io/illusts/streamline-icon-statistic-research%40400x400.png',
    description: `개발자나 디자이너처럼 제품을 만드는 사람들이 가장 크게 착각하는 것 중 하나는 바로 "내가 좋다고 생각한 아이디어를 다른 사람도 좋아할거야"라고 생각하는 것이죠.\n\n시장에서 제대로 작동하는 제품을 만들기 위해서는 철저한 데이터 기반의 사고와 빠른 실행력이 필요해요. 한번도 제품을 실제로 시장에 배포한 적이 없는 상황에서 이야기하는 가설들은 말 그대로 뇌피셜이기 때문에, 빠르게 제품을 사용자들에게 던져주고 써보라고 한 다음에 조금씩 방향을 잡아나가야 하는 것이죠.\n\n여러분은 루비콘 샌드박스에서 아이디어를 매우 작은 단위의 기능으로 구성된 MVP로 구현하고, 실제로 사용자들이 여러분의 제품을 사용하면서 어떻게 반응하는지 관찰하고 분석하며 제품을 조금씩 개선하면서, 내가 원하는 제품이 아니라 사용자들이 원하는 제품을 만드는 방법을 배울 거에요.`,
  },
  {
    title: '업계를 리드하는\n회사의 문화를 경험해보아요',
    summary:
      '업계를 리드하는 회사들은 모두 좋은 문화를 가지고 있어요. 루비콘 샌드박스에서 좋은 문화란 무엇인지, 왜 문화가 조직에 도움이 된다는 것인지 직접 경험해보세요',
    image: 'https://assets.lubycon.io/illusts/streamline-icon-team-idea-puzzle-1@400x400.png',
    description: `업계를 리드하는 회사들은 모두 좋은 문화를 가지고 있어요. 이런 문화들은 공통적으로 신속한 커뮤니케이션, 투명한 정보 공유, 가감없는 피드백 등을 내세우며, 조직의 구성원들이 안정감을 느끼며 최대한 퍼포먼스를 낼 수 있는 환경을 만드는 역할을 하죠.\n\n하지만 여러분은 그런 회사에 입사하기 전까지는 그런 문화를 경험해볼 수 없어요. 그리고 이런 문화가 익숙하지 않다면, 당장 팀원들에게 나의 상태를 어떻게 공유하며 협업을 해야 하는지, 어떻게 피드백을 줘야하는지도 어렵게 다가오죠.\n\n그래서 아이러니하게도 좋은 문화를 가지고 있는 회사들은 이런 문화를 어색해하지 않고 잘 적응할 수 있는 사람들을 찾고 있어요. 컬쳐 면접이나 임원 면접이라는 이름으로 말이죠.\n\n하지만 루비콘 샌드박스에는 이미 그런 곳에서 일을 하고 좋은 문화에 익숙한 멘토들이 있어요. 그래서 루비콘 팀에는 오래 전부터 신속한 커뮤니케이션, 투명한 정보 공유, 가감없는 피드백 등의 문화가 자리잡혀있습니다.\n\n여러분은 루비콘 샌드박스에서 멘토와 함께 제품을 개발하면서, 왜 이런 문화가 팀의 퍼포먼스에 도움이 되는지, 그리고 정말로 손발이 척척 맞는 협업이라는 것이 무엇인지, 자연스럽게 경험하게 될 거에요.`,
  },
  {
    title: '메이트들이 직접 준비한\n꿀팁을 들어보세요',
    summary:
      '여러분과 함께할 메이트들이 지금까지 일을 하며 쌓은 경험을 정리한 여러가지 세션이 준비되어있어요. 이 노하우와 꿀팁을 모두 전수해드릴게요.',
    image: 'https://assets.lubycon.io/illusts/streamline-icon-creative-idea-desk%40400x400.png',
    description: `샌드박스를 진행하는 동안 2주마다 열리는 올핸즈 미팅에서는 멘토들이 직접 준비한 세션을 진행합니다.\n\n이 세션에서 여러분은 좋은 커뮤니케이션은 어떻게 하는 것인지, MVP는 왜 정의해야 하는 것인지, 연봉 협상은 어떤 전략으로 해야하는지, 동료에게 피드백을 어떻게 해야 하는지 등 여러분이 앞으로 제품을 만드는 메이커 직군인 개발자나 디자이너로써 살아가기 위해 필요한 다양한 지식들을 배울 거에요.\n\n만약 내용이 이해되지 않아도 걱정마세요. 우리 팀의 멘토에게 이해가 되지 않는 부분에 대해서 물어보면 이해할 때까지 붙잡고 알려줄테니까요.`,
  },
  {
    title: '일방적으로 가르침을 주는 멘토가 아닌,\n팀원으로써의 메이트',
    summary:
      '루비콘 샌드박스의 메이트는 일반적인 멘토링과 다르게 일방적으로 가르침을 주는 관계가 아니에요. 일 잘 하는 사람들과 함께 일하며 자연스럽게 학습하는 경험을 해보세요.',
    image: 'https://assets.lubycon.io/illusts/streamline-icon-work-desk-4%40400x400.png',
    description: `루비콘 샌드박스의 멘토는 프론트엔드 개발자, 백엔드 개발자, 프로덕트 디자이너로 구성된 하나의 팀을 이끄는 리더에요.\n\n멘토 또한 팀원이기 때문에 매터모스트 메신저나 팀의 미팅에 참석해서 함께 좋은 제품을 만들 아이디어를 나누고 방향성을 제시하며 여러분과 함께 12주 동안 같은 팀원으로써 제품을 만들게 됩니다.\n\n또한 루비콘 샌드박스는 멘토와 멘티가 함께 할 수 있는 공식적으로 통해 많은 이야기를 나눌 수 있는 프로그램들을 운영하고 있어요.\n\n여러분은 멘토와 2주에 한번씩 진행하는 1 on 1 커피챗과 함께 자기 평가를 진행하고, 점수가 낮게 나온 항목은 왜 점수가 낮은지, 이 항목을 개선하려면 어떻게 해야할 지에 대해 깊은 이야기를 나누고 다음 2주 동안 실행해야 하는 액션 아이템을 정하게 될 거에요.\n\n물론 1 on 1 커피챗은 말 그대로 편하고 가벼운 자리이기 때문에, 이 시간에 개인적인 고민을 상담하셔도 상관없어요. 이 자리에서 나눴던 둘 만의 이야기는 절대 다른 멘토들에게 공유되지 않거든요.`,
  },
  {
    title: '폭발적인 퍼포먼스를 낼 수 있는\n모든 인프라를 지원해줘요',
    summary:
      '12주라는 짧은 기간 안에 정말 제품을 배포하고 개선까지 해볼 수 있을까요? 걱정마세요. 루비콘 팀은 여러분의 제품 개발 속도를 위해 모든 기술적 서포트를 지원하고 있거든요.',
    image: 'https://assets.lubycon.io/illusts/streamline-icon-app-network-launch%40400x400.png',
    description: `루비콘 샌드박스는 여러분이 12주라는 짧은 시간 안에 폭발적인 퍼포먼스를 내어 제품을 개발하고 사용자의 데이터를 분석하며, 다시 제품을 개선할 수 있는 최적의 기술적 환경을 제공하고 있습니다.\n\n이전 기수의 멘티들이 준비해놓은 루비콘 프론트엔드 챕터의 공통 라이브러리를 사용하거나, 루비콘 팀의 DevOps 길드에서 구축해놓은 데이터 파이프라인, 시각화 분석 도구, CI/CD를 적극적으로 활용해서 미친 속도로 MVP를 만들어보세요.\n\n이렇게 크고 복잡한 규모의 인프라를 구축하고 운영하는 데는 전문적인 DevOps 엔지니어가 필요해요. 그래서 작은 규모의 회사에서는 리소스 부족으로 인해 시도하지 못 하는 경우가 대부분이지만, 네카라쿠배처럼 업계를 리드하는 회사들은 모두 전문적인 인프라와 이를 개발하고 운영하는 플랫폼 조직을 가지고 있죠.\n\n여러분은 루비콘 샌드박스에서 이러한 인프라 시스템을 직접 사용하며, 플랫폼 조직이 있는 회사에서는 대형 인프라를 어떻게 구축하고 운영하는지 직접 뜯어보며 지식을 쌓을 수도 있고, 제품을 만드는 개발자가 플랫폼 조직과 어떻게 협업해야 하는 지도 배울 거에요.\n\n만약 여러분이 원한다면 직접 DevOps 길드에 가입해서 루비콘의 DevOps 인프라 시스템에 기여할 수도 있어요.`,
  },
];

const BenefitSection = () => {
  const { spacing } = useTheme<Theme>();
  const [isOpenModal, openModal, closeModal] = useBooleanState();
  const [selectedBenefit, selectBenefit] = useState<MentoringBenefit | null>(null);

  return (
    <Box component="section" sx={{ padding: { md: `${spacing(34)} 0`, xs: `${spacing(10)} 0` }, zIndex: 1 }}>
      <Container>
        <Typography component="h2" sx={{ fontWeight: 'bold', fontSize: { md: 56, xs: 24 } }} gutterBottom>
          루비콘 샌드박스에서 경험해보세요
        </Typography>
        <Typography sx={{ marginBottom: { md: '82px', xs: '40px' } }}>
          루비콘 샌드박스는 단순히 개발이나 디자인 스킬에 대한 방향을 가이드하는 것이 아니라,
          <br />
          실제로 좋은 문화를 가진 기업에서 일 하는 것과 유사한 경험을 통해 성장하는 것을 목표로 하고 있어요.
        </Typography>
        <Grid container spacing={2} justifyContent="center">
          {benefits.map(({ title, image, description, summary }, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <FadeUp>
                <BenefitCard
                  key={index}
                  title={title}
                  description={summary}
                  image={image}
                  onClick={() => {
                    mentoringIntroPageLogger.click('benefit_card', { title });
                    openModal();
                    selectBenefit({ title, image, description, summary });
                  }}
                />
              </FadeUp>
            </Grid>
          ))}
        </Grid>
      </Container>
      <BenefitDialog
        open={isOpenModal}
        onClose={() => {
          closeModal();
          selectBenefit(null);
        }}
        benefit={selectedBenefit}
      />
    </Box>
  );
};

interface Props extends ComponentProps<typeof Dialog> {
  benefit: MentoringBenefit | null;
}
const BenefitDialog = ({ benefit, ...rest }: Props) => {
  return (
    <Dialog {...rest}>
      <Box
        component="img"
        src={benefit?.image}
        alt=""
        sx={{ width: 120, paddingTop: 5, paddingLeft: 2, margin: '0 auto' }}
      />
      <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }}>{benefit?.title}</DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{ whiteSpace: 'pre-line', color: 'common.black', marginBottom: 5 }}
          dangerouslySetInnerHTML={{ __html: benefit?.description ?? '' }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default BenefitSection;
