import React, { useMemo, useCallback } from 'react';
import { Theme, Typography, Box, useTheme, Button } from '@mui/material';
import FadeUp from 'components/Animated/FadeUp';
import SvgElements from './SvgElements';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { SxProps } from '@mui/system';
import sandboxData from 'data/sandbox.json';
import WordSlider from 'components/Animated/WordSlider';
import { useSandboxApply } from 'hooks/useSandboxApply';
import { SandboxApplyStatus } from 'models/sandbox';
import { mentoringIntroPageLogger } from 'utils/loggers';
import { navigate } from 'gatsby';

const today = new Date();
const endDate = new Date(sandboxData.applyEndDate);
const preApplyEndDate = new Date(sandboxData.preApplyEndDate);

const titleStyle: SxProps<Theme> = {
  textAlign: 'center',
  marginTop: {
    md: '32px',
    xs: '16px',
  },
  fontSize: {
    md: '20px',
    xs: '16px',
  },
};

const IntroSection = () => {
  const { spacing } = useTheme<Theme>();
  const { status } = useSandboxApply();
  const dday = useMemo(() => {
    const targetEndDate = status === SandboxApplyStatus.사전신청 ? preApplyEndDate : endDate;
    const diff = differenceInCalendarDays(targetEndDate, today);
    if (diff === 0) {
      return 'D-Day';
    } else if (diff < -100) {
      return '';
    } else if (diff < 0) {
      return '';
    } else {
      return `D-${diff}`;
    }
  }, [status]);

  const handleApply = useCallback(() => {
    mentoringIntroPageLogger.click('apply_button', {
      position: 'intro_section',
    });

    const 알림_신청_구글_폼 = 'https://forms.gle/DjyTPgscQaJTnTyF9';
    const 샌드박스_신청_페이지 = '/mentoring/apply-forms/';
    if (status === SandboxApplyStatus.사전신청) {
      window.open(알림_신청_구글_폼);
    } else if (status === SandboxApplyStatus.신청기간) {
      navigate(샌드박스_신청_페이지);
    } else {
      window.open('mailto:help@lubycon.io?subject=루비콘 샌드박스에 대해 문의합니다');
    }
  }, [status]);

  const ctaText = useMemo(() => {
    switch (status) {
      case SandboxApplyStatus.사전신청:
        return '사전신청하고 20% 할인받기';
      case SandboxApplyStatus.신청기간:
        return '지원하기';
      default:
        return '문의하기';
    }
  }, [status]);

  return (
    <Box
      sx={{ height: `100vh`, backgroundColor: '#FFFFFF', padding: `0 ${spacing(2)}` }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box display="flex" flexDirection="column" alignItems="center" sx={{ zIndex: 1 }}>
        {dday === '' ? null : (
          <Box sx={{ backgroundColor: '#C5E5DD', padding: '0 14px' }}>
            <FadeUp delay={0}>
              <Typography
                variant="h1"
                sx={{
                  whiteSpace: 'nowrap',
                  fontWeight: 'bold',
                  fontSize: {
                    md: 24,
                    xs: 16,
                  },
                  lineHeight: 1,
                  transform: 'translateY(-8px)',
                }}
              >
                샌드박스 {status === SandboxApplyStatus.사전신청 ? '사전신청' : '신청'} 마감 {dday}
              </Typography>
            </FadeUp>
          </Box>
        )}
        <FadeUp delay={0.1} duration={0.7}>
          <Typography
            variant="h2"
            sx={{
              fontWeight: 'bold',
              fontSize: {
                md: 60,
                xs: 24,
              },
              marginTop: {
                md: '22px',
                xs: 0,
              },
              lineHeight: '81px',
              '& strong': { color: 'primary.main' },
            }}
          >
            LUBYCON <Box component="strong">SANDBOX</Box>
          </Typography>
        </FadeUp>
        <FadeUp delay={1}>
          <Typography sx={titleStyle}>
            개발/디자인만 잘 하면 좋은 개발자/디자이너가 될 수 있는 걸까요? <br />
            No. 업계를 리드하는 회사들은{' '}
            <WordSlider
              delaySecond={2}
              texts={['제품을 만들 줄 아는', '커뮤니케이션 잘 하는', '스스로 성장할 수 있는']}
            />{' '}
            사람을 찾고 있어요.
          </Typography>
        </FadeUp>
        <Box
          component={Button}
          color="primary"
          variant="contained"
          size="large"
          onClick={handleApply}
          sx={{
            fontSize: {
              md: 24,
              xs: 16,
            },
            fontWeight: 'bold',
            padding: '8px 50px',
            marginTop: 10,
          }}
        >
          {ctaText}
        </Box>
      </Box>
      <Box sx={{ zIndex: 0 }}>
        <SvgElements />
      </Box>
    </Box>
  );
};

export default IntroSection;
