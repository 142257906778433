import { Box, Typography, Dialog, DialogTitle, DialogContent, DialogContentText } from '@mui/material';
import React, { useState, ComponentProps } from 'react';
import FadeUp from 'components/Animated/FadeUp';
import { useBooleanState } from '@lubycon/react';
import { mentoringIntroPageLogger } from 'src/utils/loggers';

interface MentoringSession {
  title: string;
  color: string;
  description: string;
}
const sessions: MentoringSession[] = [
  {
    title: '👬 아름다운 커뮤니케이션 스킬',
    color: '#8D9098',
    description:
      '우리의 추억 속 대학교 조별과제는 왜 안 좋은 기억으로 남는 걸까요?\n\n이렇게 서로 잘 모르는 사람들이 모여서 함께 협업을 할 때, 우리는 어떻게 해야 효율적이고 추진력있게 일을 처리할 수 있는 걸까요?\n\n루비콘 팀과 함께 투명하고 신속한 커뮤니케이션, 그리고 팀원들과 커뮤니케이션을 할 때 어떤 점들을 고민해봐야 하는지 함께 알아보아요.',
  },
  {
    title: '🚀 MVP 기획은 결국 뇌피셜, 검증은 시장에서.',
    color: '#8D9098',
    description:
      '처음에는 분명 깔끔한 아이디어로 시작했던 우리의 제품. 하지만 막상 만들다보니 왠지 이런 기능도 필요할 것 같고, 저런 기능도 필요할 것 같은 생각이 들어요.\n\n만약 이런 생각이 든다면, 일단 제품 개발을 멈추세요. 지금은 MVP를 명확하게 정의해야 할 때니까요.\n\n많은 조직들은 최소 비용으로 최대의 효과를 만들어내는 개발 방법론으로 MVP(Minimum Viable Product)를 이야기하고 있어요. MVP는 제품 개발에 있어서 굉장히 중요한 요소이지만, 회사에 들어가서 직접 경험해보기 전까지는 이런 개념이 있다는 것 조차 모르는 경우도 있죠.\n\n그래서 루비콘 팀이 MVP에 대한 모든 것을 준비했습니다.',
  },
  {
    title: '💰 연봉 협상 잘 하는 방법 (feat. 스톡옵션)',
    color: '#8D9098',
    description:
      '연봉 협상은 신입이든 경력직이든 누구나 한번 쯤은 겪게 되는 필수 코스이지만, 막상 연봉 협상을 하려고 하면 내가 정말 이 정도의 돈을 요구해도 되는 것인지, 스톡옵션은 또 뭐고 클리프랑 베스팅은 또 뭔지... 머리가 너무 아파와요.\n\n루비콘 팀과 함께 연봉 협상에 대한 다양한 전략들과 스톡옵션이나 RSU와 같이 회사에서 제안하는 다양한 증권 보상들에 대해서 알아볼까요?',
  },
  {
    title: '🔈 좋은 피드백은 어떻게 줘야 할까?',
    color: '#8D9098',
    description:
      '많은 기업들이 수평적인 관계에서 비롯되는 투명한 피드백이라는 문화를 도입하려고 하고 있지만, 막상 조직에 도입하려고 하면 조직원들의 소극적인 태도와 방어적인 심리 기재 때문에 좌절되는 경우가 많아요.\n\n하지만 구글이나 넷플릭스 같은 외국 기업들은 이런 문화가 잘 정착되어 있다는데...이런 피드백 문화는 대한민국의 문화와는 맞지 않는걸까요?\n\n아니에요. 사실 피드백을 단순히 부정적인 이야기를 하는 것이 아니라 상대방과 내가 모두 발전할 수 있는 논의를 시작하기 위한 방아쇠에요.\n\n그래서 올바른 피드백이란 무엇인지, 우리 팀에 피드백이라는 문화를 정착시키기 위해 신경써야 하는 것이 무엇인지 정리해보았어요!',
  },
  {
    title: '🏃‍♂️ 삐걱삐걱 발 맞추기',
    color: '#8D9098',
    description:
      '우리는 항상 다양한 사람들과 협업을 하고 있죠. 그 중에서도 특히 개발자와 디자이너는 IT 업계의 대표적인 협업 파트너라고 해도 과언이 아닌데요.\n\n개발자와 디자이너는 서로 밀접한 분야에서 일을 하고 커뮤니케이션을 많이 하는 직군이지만, 좋은 제품에 대한 서로의 가치관이 조금 달라서 부딫히는 경우가 생길 수도 있어요.\n\n하지만 두 직군 모두 좋은 제품을 만들고 싶다는 마음은 같기 때문에, 상대방이 중요하게 생각하는 것이 무엇인지 조금만 고민하고 배려하는 것만으로도 더 좋은 팀을 만들 수 있을거에요.\n\n그럼 개발자가 디자이너와 협업할 때, 그리고 디자이너가 개발자와 협업할 때 어떤 것들을 신경쓰고 고민해야 할까요? 루비콘 팀과 함께 알아보아요!',
  },
  {
    title: '👨‍🏫 핵심 인재의 길',
    color: '#8D9098',
    description:
      '핵심 인재는 어떤 조직 내에서 특별하게 인정받는 사람들을 이야기해요. 핵심 인재가 되면 많은 경제적 보상을 받거나 이직이 수월해지고, 다른 회사의 CEO들에게 커피챗 신청이 들어오는 등 다양한 경험들을 해볼 수 있죠.\n\n그렇다면 핵심 인재는 특별히 재능이 뛰어난 천재같은 사람들만 될 수 있는 걸까요?\n\n아니에요. 핵심 인재는 재능만으로 될 수 있는 게 아니라, 나의 성과를 명확하게 측정하고 기록하거나 단순히 개발이나 디자인에 대한 능력만 키우는 것이 아니라 비즈니스 인사이트를 키우는 등 방향성이 또렷한 노력이 만들어내는 것이에요.\n\n루비콘 팀과 함께 핵심 인재가 되면 어떤 삶이 기다리고 있는지, 왜 핵심 인재라는 목표를 만들어야 하는 것인지 알아보아요.',
  },
  {
    title: '👌 실패해도 괜찮아. 실패하며 성장하기.',
    color: '#8D9098',
    description:
      '많은 사람들은 실패라는 단어를 두려워하고 실패를 자꾸 피하려고 해요. 하지만 실패라는 것은 피한다고 해서 피해질 수 있는 것이 아니죠.\n\n그럼 어떻게 해야할까요? 실패하는 상황을 받아들이고 포기하라는 걸까요?\n\n아니에요. 여러분은 실패를 분석하고 명확한 액션아이템을 만들어가면서 실패라는 녀석을 철저하게 이용해야 합니다. 즉, 실패를 이용하면서 성장해야 한다는 것이에요.\n\n루비콘 팀과 함께 실패를 경험했을 때 어떤 분석을 하고 어떻게 액션 아이템을 만들어야 하는지 알아보아요.',
  },
  {
    title: '📊 a/b테스트, 지표 수집을 통한 Data Driven Decision',
    color: '#8D9098',
    description:
      '우리가 이번 주에 배포한 제품을 실제로 사용자들이 잘 사용하고 있는지, 혹은 이 제품이 얼마 만큼의 성과를 만들어 내고 있는지 어떻게 알 수 있을까요?\n\n그 정답은 바로 데이터에 있습니다. 데이터를 통해 우리가 세웠던 가설대로 사용자들이 반응하고 있는지, 우리가 예측하지 못 했던 변수는 없는지, 우리가 맞는 방향으로 가고 있는지를 알아내는 것이죠.\n\n그래서 루비콘 팀은 여러분이 데이터 기반 의사결정을 경험할 수 있도록 대표적인 실험 방법론인 A/B 테스트를 소개해드리고, 어떻게 해야 빠르게 이런 실험 환경을 구축할 수 있는지 소개해드릴거에요.',
  },
];

const MentoringSessions = () => {
  const [isOpenModal, openModal, closeModal] = useBooleanState();
  const [selectedSession, selectSession] = useState<MentoringSession | null>(null);

  return (
    <Box
      sx={{
        marginTop: {
          md: '52px',
          xs: '24px',
        },
      }}
    >
      <Typography sx={{ fontWeight: 'bold', fontSize: 18 }}>Lubycon Sessions</Typography>
      <Typography sx={{ marginBottom: 2, fontSize: 14 }}>
        각 세션을 클릭하시면 조금 더 자세한 내용을 보실 수 있어요!
      </Typography>
      <Box sx={{ marginLeft: '-12px' }} display="flex" flexWrap="wrap">
        {sessions.map(({ title, color, description }) => (
          <Box
            component={FadeUp}
            key={title}
            sx={{
              flexGrow: 1,
              marginBottom: 1.5,
              marginLeft: 1,
              cursor: 'pointer',
              transition: 'transform .2s ease-in',
              boxShadow: 1,
              '&:hover': {
                transform: 'translateY(-6px)',
              },
            }}
            onClick={() => {
              mentoringIntroPageLogger.click('mentor_session', { title });
              selectSession({ title, color, description });
              openModal();
            }}
          >
            <Typography
              sx={{
                color: '#ffffff',
                padding: {
                  md: '12px 16px',
                  xs: '10px 8px',
                },
                borderRadius: '8px',
                whiteSpace: 'nowrap',
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: {
                  md: 16,
                  sm: 14,
                  xs: 12,
                },
                backgroundColor: color,
              }}
            >
              {title}
            </Typography>
          </Box>
        ))}
      </Box>
      <SessionDialog
        open={isOpenModal}
        onClose={() => {
          closeModal();
          selectSession(null);
        }}
        session={selectedSession}
      />
    </Box>
  );
};

interface Props extends ComponentProps<typeof Dialog> {
  session: MentoringSession | null;
}
const SessionDialog = ({ session, ...rest }: Props) => {
  return (
    <Dialog {...rest}>
      <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold', marginTop: 3 }}>{session?.title}</DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{ whiteSpace: 'pre-line', color: 'common.black', marginBottom: 5 }}
          dangerouslySetInnerHTML={{ __html: session?.description ?? '' }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default MentoringSessions;
