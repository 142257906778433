import React, { useMemo } from 'react';
import { getArc } from '@lubycon/utils';

interface Props {
  className?: string;
  fill: string;
  size: number;
  degree: number;
}
const ArcSvg = ({ size, fill, degree, className }: Props) => {
  const arcData = useMemo(
    () =>
      getArc({
        x: size / 2,
        y: size / 2,
        radius: size / 2,
        degree,
      }),
    [size, degree]
  );

  return (
    <svg width={size} height={size} className={className}>
      <path d={arcData} fill={fill} />
    </svg>
  );
};

export default ArcSvg;
