import React from 'react';

interface Props {
  className?: string;
  fill: string;
  size: number;
}
const CircleSvg = ({ size, fill, className }: Props) => {
  return (
    <svg width={size} height={size} className={className}>
      <circle cx={size / 2} cy={size / 2} r={size / 2} fill={fill} />
    </svg>
  );
};

export default CircleSvg;
