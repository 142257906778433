import React, { useMemo } from 'react';
import { NoSsr, useMediaQuery, useTheme, Box } from '@mui/material';
import Rellax from 'react-rellax-wrapper';
import CircleSvg from 'components/Utils/svg/CircleSvg';
import ArcSvg from 'components/Utils/svg/ArcSvg';
import TriangleSvg from 'components/Utils/svg/TriangleSvg';

const opacity = 0.7;

const getElementSize = (size: number, isMobile: boolean) => {
  return isMobile === true ? size / 3.5 : size;
};

const SvgElements = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const sizes = useMemo(() => {
    return {
      redCircle: getElementSize(204, matches),
      yellowArc: getElementSize(710, matches),
      greenArc: getElementSize(350, matches),
      blueTriangle: {
        width: getElementSize(80, matches),
        height: getElementSize(100, matches),
      },
    };
  }, [matches]);

  return (
    <NoSsr>
      <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%' }}>
        <Rellax speed={0.5}>
          <Box
            component={CircleSvg}
            sx={{
              position: 'absolute',
              top: 100,
              right: '25%',
              opacity,
            }}
            fill="#E73C22"
            size={sizes.redCircle}
          />
        </Rellax>
        <Rellax speed={3}>
          <Box
            component={ArcSvg}
            size={sizes.yellowArc}
            sx={{
              position: 'absolute',
              top: {
                md: 180,
                xs: 250,
              },
              right: {
                md: '12%',
                xs: '10%',
              },
              transform: 'rotate(-16deg)',
              opacity,
            }}
            fill="#F1AD25"
            degree={180}
          />
        </Rellax>
        <Rellax speed={1.5}>
          <Box
            component={ArcSvg}
            size={sizes.greenArc}
            fill="#00C090"
            degree={180}
            sx={{
              position: 'absolute',
              top: {
                md: 750,
                xs: 550,
              },
              left: '5%',
              transform: 'rotate(29.31deg)',
              opacity,
            }}
          />
        </Rellax>
        <Rellax speed={5}>
          <Box
            component={TriangleSvg}
            size={{ width: sizes.blueTriangle.width, height: sizes.blueTriangle.height }}
            fill="#2248E6"
            points={[
              [0.8, 0],
              [1, 0.95],
              [0, 1],
            ]}
            sx={{
              position: 'absolute',
              top: {
                md: 1200,
                xs: 750,
              },
              right: '40%',
              transform: 'rotate(25.35deg)',
              opacity,
            }}
          />
        </Rellax>
      </Box>
    </NoSsr>
  );
};

export default SvgElements;
