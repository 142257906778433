import { useStaticQuery, graphql } from 'gatsby';

interface ReviewsResult {
  allReviewJson: {
    nodes: Array<{
      author: { name: string; profileImg: string; role: string };
      title: string;
      description: string;
      url: string;
    }>;
  };
}

export function useMentoringReviews() {
  const {
    allReviewJson: { nodes: reviews },
  } = useStaticQuery<ReviewsResult>(graphql`
    query AllReviewsQuery {
      allReviewJson {
        nodes {
          author {
            name
            profileImg
            role
          }
          title
          description
          url
        }
      }
    }
  `);

  return reviews;
}
