import React, { useCallback, useMemo } from 'react';
import { navigate } from 'gatsby';
import { Box, Container, Typography, Theme, useTheme, Button, Card } from '@mui/material';
import { getLogoSrc } from 'utils/logo';
import { mentoringIntroPageLogger } from 'utils/loggers';
import { useSandboxApply } from 'src/hooks/useSandboxApply';
import { SandboxApplyStatus } from 'models/sandbox';
import { commaizeNumber } from '@lubycon/utils';
import { grey } from '@mui/material/colors';
import sandboxData from 'data/sandbox.json';

const ApplySection = () => {
  const { status } = useSandboxApply();
  const { spacing } = useTheme<Theme>();
  const logo = getLogoSrc('symbol');

  const price =
    status === SandboxApplyStatus.사전신청
      ? sandboxData.price - sandboxData.price * sandboxData.discountRate
      : sandboxData.price;

  const handleApply = useCallback(() => {
    mentoringIntroPageLogger.click('apply_button', {
      position: 'bottom_section',
    });

    const 알림_신청_구글_폼 = 'https://forms.gle/DjyTPgscQaJTnTyF9';
    const 샌드박스_신청_페이지 = '/mentoring/apply-forms/';
    if (status === SandboxApplyStatus.사전신청) {
      window.open(알림_신청_구글_폼);
    } else if (status === SandboxApplyStatus.신청기간) {
      navigate(샌드박스_신청_페이지);
    } else {
      window.open('mailto:help@lubycon.io?subject=루비콘 샌드박스에 대해 문의합니다');
    }
  }, [status]);

  const { title, ctaText } = useMemo(() => {
    switch (status) {
      case SandboxApplyStatus.사전신청:
        return {
          title: '지금 바로 사전신청하세요!',
          ctaText: '사전신청하고 20% 할인받기',
        };
      case SandboxApplyStatus.신청기간:
        return {
          title: '지금 바로 지원하세요!',
          ctaText: '지원하기',
        };
      default:
        return {
          title: '아직 모집이 시작되지 않았어요',
          ctaText: '문의하기',
        };
    }
  }, [status]);

  return (
    <Box
      component="section"
      sx={{
        padding: {
          md: `${spacing(8)} 0`,
          xs: `${spacing(5)} 0`,
        },
      }}
    >
      <Container>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box component="img" src={logo} alt="루비콘 로고" sx={{ width: 74 }} />
        </Box>
        <Typography
          variant="h4"
          component="h2"
          sx={{
            fontWeight: 'bold',
            textAlign: 'center',
            fontSize: {
              md: 56,
              xs: 30,
            },
            marginTop: {
              md: 3,
              xs: 2,
            },
          }}
        >
          {title}
        </Typography>
        <Box display="flex" justifyContent="center">
          <Card sx={{ margin: `${spacing(5)} 0`, padding: `${spacing(2)} ${spacing(5)}` }}>
            <Box display="flex" justifyContent="space-between">
              <Typography sx={{ color: grey['800'] }}>12주 과정</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Typography
                sx={{
                  fontSize: { md: 46, xs: 40 },
                  lineHeight: 1.2,
                  color: 'primary.main',
                  fontWeight: 'bold',
                }}
              >
                {commaizeNumber(price)}원
              </Typography>
            </Box>
          </Card>
        </Box>
        <Typography
          sx={{
            textAlign: 'center',
            marginBottom: '54px',
            fontSize: {
              md: 18,
              xs: 16,
            },
          }}
        >
          루비콘은 12주 동안의 프로젝트를 완수할 수 있는 최소한의 책임감을 가진 팀원들과 함께하기를,
          <br />
          모든 팀원에게 루비콘 샌드박스가 열정있는 사람들과의 좋은 기억으로 남기를 바랍니다.
          <br />
          기타 문의사항이 있다면{' '}
          <Box
            component="a"
            sx={{ color: 'primary.main' }}
            href="mailto:help@lubycon.io"
            target="_blank"
            rel="noreferrer"
          >
            help@lubycon.io
          </Box>
          로 보내주세요!
        </Typography>
        <Box display="flex" justifyContent="center">
          <Box
            component={Button}
            color="primary"
            variant="contained"
            size="large"
            onClick={handleApply}
            sx={{
              fontSize: {
                md: 24,
                xs: 16,
              },
              fontWeight: 'bold',
              padding: '8px 50px',
            }}
          >
            {ctaText}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default ApplySection;
