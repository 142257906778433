import React, { useState, useRef } from 'react';
import { useInterval, useElementSize } from '@lubycon/react';
import { Box } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';

interface Props {
  texts: string[];
  delaySecond: number;
}
const WordSlider = ({ texts, delaySecond }: Props) => {
  const [showCount, setShowCount] = useState(0);

  useInterval(() => {
    setShowCount(showCount + 1);
  }, delaySecond * 1000);

  const ref = useRef<HTMLElement | null>(null);
  const { width } = useElementSize(ref);

  return (
    <>
      <Box
        component="span"
        display="inline-flex"
        flexDirection="column"
        sx={{
          borderBottom: '1px solid #000',
          textAlign: 'center',
          width: width === 0 ? 'auto' : width,
        }}
      >
        <AnimatePresence exitBeforeEnter={true}>
          {texts.map((text, index) =>
            index === showCount % texts.length ? (
              <Box
                key={`${text}-${index}`}
                sx={{
                  listStyle: 'none',
                  margin: 0,
                  padding: 0,
                  fontWeight: 'bold',
                }}
                component={motion.span}
                variants={variants}
                initial="initial"
                animate="show"
                exit="exit"
                transition={{ type: 'easeOut', duration: 0.5 }}
              >
                {text}
              </Box>
            ) : null
          )}
        </AnimatePresence>
      </Box>
      <Box ref={ref} component="span" display="flex" flexDirection="column" sx={{ position: 'fixed', left: -10000 }}>
        {texts.map((text, index) => (
          <span key={`${text}-${index}`}>{text}</span>
        ))}
      </Box>
    </>
  );
};

const variants = {
  initial: {
    opacity: 0,
    y: 30,
  },
  show: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: -30,
  },
};

export default WordSlider;
