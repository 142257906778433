import React, { useState, useCallback, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import RouteLink from 'components/Utils/RouteLink';
import { motion } from 'framer-motion';

const RecruitMentorCircle = () => {
  const [viewBoxSize, setViewboxSize] = useState(0);
  const ref = useCallback((el: HTMLElement | null) => {
    if (el != null) {
      setViewboxSize(el.clientWidth);
    }
  }, []);
  const circleSize = viewBoxSize / 3;

  const [blink, setBlink] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      setBlink(state => !state);
    }, 800);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box
      component={RouteLink}
      to="https://lubycon.notion.site/3a06c7b26d894b7d95aefa135eaa88ea"
      sx={{ textDecoration: 'none' }}
    >
      <motion.div
        ref={ref}
        style={{ originX: 0.5, originY: 0.5 }}
        initial="small"
        animate={blink ? 'small' : 'large'}
        variants={{
          small: {
            opacity: 0.5,
            scale: 0.9,
          },
          large: {
            opacity: 1,
            scale: 1,
          },
        }}
        transition={{ type: 'spring', bounce: 0.7, duration: 1.5 }}
      >
        <svg width={viewBoxSize} height={viewBoxSize} xmlns="http://www.w3.org/2000/svg" version="1.1">
          <circle cx={viewBoxSize / 2} cy={viewBoxSize - circleSize} r={circleSize} fill="#DDDDDD" stroke="none" />
        </svg>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            position: 'absolute',
            width: circleSize * 2,
            height: circleSize * 2,
            top: viewBoxSize - circleSize * 2,
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            color={grey['700']}
            sx={{ fontSize: { md: 'h5.fontSize', xs: 'h6.fontSize' } }}
          >
            CLICK ME
          </Typography>
        </Box>
      </motion.div>
      <Typography variant="subtitle1" fontWeight="bold" sx={{ marginTop: 2, textAlign: 'center', color: grey['800'] }}>
        루비콘 팀과 함께할
        <br />
        메이트를 모집하고 있어요!
      </Typography>
    </Box>
  );
};

export default RecruitMentorCircle;
