import React, { useEffect } from 'react';
import { parseQueryString } from '@lubycon/utils';
import Page from 'components/Layout/Page';
import { mentoringIntroPageLogger } from 'utils/loggers';
import IntroSection from './IntroSection';
import BenefitSection from './BenefitSection';
import CurriculumSection from './CurriculumSection';
import MentorSection from './MentorSection';
import ApplySection from './ApplySection';
import ReviewSection from './ReviewSection';

const SandboxIntroPage = () => {
  useEffect(() => {
    mentoringIntroPageLogger.view();
  }, []);

  useEffect(() => {
    const referrer = document.referrer;
    const qs = location.search ?? ''; // eslint-disable-line
    const parsedQS = parseQueryString(qs.replace('?', ''));
    const utmSource = parsedQS.utm_source;

    mentoringIntroPageLogger.impression('mentoring_project_intro_referrer', {
      referrer,
      utmSource: (Array.isArray(utmSource) ? utmSource.join(',') : utmSource) ?? 'unknown',
    });
  }, []);

  return (
    <Page
      title="루비콘 샌드박스"
      description="우리는 단순히 제품을 개발하는 것이 아닌, 치열하게 문제를 정의하고 해결하는 것에 집중합니다."
    >
      <IntroSection />
      <BenefitSection />
      <CurriculumSection />
      <MentorSection />
      <ReviewSection />
      <ApplySection />
    </Page>
  );
};

export default SandboxIntroPage;
